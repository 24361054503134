import { orderBy } from 'firebase/firestore';
import { values } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Suppliers } from '@bridebook/models';
import { TranslatedError } from '@bridebook/toolbox/src';
import { getExt } from '@bridebook/toolbox/src/getExt';
import { addSupplierFeedback, validateReviewFormFields } from 'app-shared/lib/reviews/utils';
import { REVIEWS_PER_PAGE, REVIEWS_PER_PAGE_AB } from 'app-shared/lib/supplier/constants';
import { getI18n } from 'lib/i18n/getI18n';
import { getUserL10n } from 'lib/i18n/selectors';
import { Action, IApplicationState, IEpicDeps } from 'lib/types';
import { getSupplierFriendlyUrlId, imgixBaseURL } from 'lib/utils';
import {
  fetchFeedbackDone,
  fetchFeedbackPromise,
  fetchSupplierPhotosDone,
  saveReviewError,
  saveReviewSuccess,
  uploadReviewPhotoError,
  uploadReviewPhotoSuccess,
} from './actions';
import { SupplierActions } from './actions-types';
import { getAreReviewsMoved, getCurrentSupplier } from './selectors';

export const saveReviewEpic = (action$: Observable<Action>, { validate, state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(SupplierActions.SAVE_REVIEW),
    withLatestFrom(state$),
    mergeMap(([, state]: [Action, IApplicationState]) => {
      const {
        supplier: {
          reviewForm: { recaptchaComplete, fields },
        },
        users: { user },
      } = state;
      const i18n = getI18n();
      const supplier = getCurrentSupplier(state);

      if (!supplier || !supplier.id) {
        throw new TranslatedError(
          'Save review: invalid supplier!',
          i18n.t('supplier:form.review.error.invalidSupplier'),
        );
      }

      const getPromise = async () => {
        await validateReviewFormFields(fields, recaptchaComplete, validate);
        return addSupplierFeedback(supplier, fields, user?.id);
      };
      return from(getPromise()).pipe(
        map(saveReviewSuccess),
        catchError((error) => of(saveReviewError(error))),
      );
    }),
  );

export const fetchFeedbackEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType(SupplierActions.FETCH_FEEDBACK),
    withLatestFrom(state$),
    mergeMap(([action, state]: [any, IApplicationState]) => {
      const { payload: activePage } = action;
      const supplier = state.supplier.supplier.data;
      const publicId = supplier?.publicId;
      const name = supplier?.name;
      const areReviewsMoved = getAreReviewsMoved(state);
      const locale = getUserL10n(state)?.locale;

      if (!publicId || !name)
        return of({
          type: SupplierActions.FETCH_FEEDBACK_STOP,
        });

      const id = getSupplierFriendlyUrlId(supplier);
      const promise = fetchFeedbackPromise(
        id,
        activePage,
        areReviewsMoved ? REVIEWS_PER_PAGE_AB : REVIEWS_PER_PAGE,
        locale,
      );

      return from(promise).pipe(
        map((result) => fetchFeedbackDone(result)),
        catchError((e) =>
          of({
            type: SupplierActions.FETCH_FEEDBACK_ERROR,
            payload: { error: e.message },
          }),
        ),
      );
    }),
  );

export const uploadReviewPhotoEpic = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(SupplierActions.UPLOAD_REVIEW_PHOTO),
    mergeMap(({ payload: { photo } }) => {
      const getPromise = async () => {
        const ext = getExt(photo.filename);
        /* eslint-disable */
        const public_id = photo.filename.replace(`.${ext}`, '');
        const imgixURL = `${imgixBaseURL}/${public_id}.${ext}`;
        /* eslint-enable */
        const result = await fetch(`${imgixURL}?faces=1&fm=json`).then((data) => data.json());
        const { PixelHeight, PixelWidth, Faces } = result;
        return {
          faces: !!Faces,
          portrait: PixelHeight > PixelWidth,
          public_id,
          ext,
          imgixURL,
        };
      };
      return from(getPromise()).pipe(
        map(uploadReviewPhotoSuccess),
        catchError((error) => of(uploadReviewPhotoError(error))),
      );
    }),
  );

export const fetchSupplierPhotosEpic = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(SupplierActions.FETCH_PHOTOS),
    mergeMap(({ payload: { supplierId } }) => {
      const promise = Suppliers._.getById(supplierId)
        .Photos.query([orderBy('order')])
        .get();

      return from(promise).pipe(
        mergeMap((data) => {
          const photos = values(data);
          return of(fetchSupplierPhotosDone(photos));
        }),
        catchError((error) =>
          of({
            type: SupplierActions.FETCH_SUPPLIER_PHOTOS_ERROR,
            payload: error,
          }),
        ),
      );
    }),
  );

export const registerSupplierCardInView = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(SupplierActions.SUPPLIER_CARD_IN_VIEW),
    mergeMap(({ payload }) => {
      if (!process.browser) {
        return of();
      }

      return of({ type: SupplierActions.VIEWED_SUPPLIER_TILE_ANALYTICS, payload });
    }),
  );

export const pageEpics = [
  fetchFeedbackEpic,
  fetchSupplierPhotosEpic,
  saveReviewEpic,
  uploadReviewPhotoEpic,
];
