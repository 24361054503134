import Router from 'next/router';
import NProgress from 'nprogress';
import { AnyAction } from 'redux';
import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import {
  catchError,
  filter,
  mergeMap,
  switchMap,
  throttleTime,
  withLatestFrom,
} from 'rxjs/operators';
import { Countries } from '@bridebook/models';
import { PartialRecursive } from '@bridebook/models/source/abstract/_';
import { IWedding } from '@bridebook/models/source/models/Weddings.types';
import { getWindowSizes } from 'app-shared/lib/app/utils/get-window-sizes';
import { getHtmlLangAttribute } from 'lib/app/utils/get-html-lang-attribute';
import { AuthActionTypes, SignOutCompleted } from 'lib/auth/action-types';
import { ChecklistActionTypes } from 'lib/checklist/action-types';
import { TaskActionTypes } from 'lib/task/action-types';
import { Action, IApplicationState, IEpic, IEpicDeps } from 'lib/types';
import { getAsPathNormalized } from 'lib/utils/url';
import { WeddingActionTypes } from 'lib/weddings/action-types';
import { updateWeddingField } from '../weddings/actions';
import {
  appError,
  fetchGroupsSuccess,
  resizeWindow,
  routeChangeComplete,
  routeChangeStart,
} from './actions';

export const routingEpic = (action$: Observable<Action>): Observable<any> => {
  const routerObserver$ = () =>
    new Observable<ReturnType<typeof routeChangeComplete> | ReturnType<typeof routeChangeStart>>(
      (observer) => {
        Router.events.on('routeChangeComplete', () => {
          NProgress.done();

          // Next Router overrides our lang setting from _document.tsx, so we need to override it again
          // @see: https://github.com/vercel/next.js/issues/18452#issuecomment-943467729
          if (document) {
            document.documentElement.lang = getHtmlLangAttribute(Router.locale);
          }

          const url = getAsPathNormalized();
          observer.next(routeChangeComplete(url, Router.query, Router.pathname));
        });

        Router.events.on('routeChangeStart', (url) => {
          const asPath = getAsPathNormalized();
          observer.next(routeChangeStart(asPath, Router.query, url, Router.locale));
          NProgress.start();
        });

        Router.events.on('routeChangeError', () => {
          NProgress.done();
        });
      },
    );
  return action$.pipe(ofType('APP_STARTED'), mergeMap(routerObserver$));
};

export const windowResizeEpic = (action$: Observable<Action>, deps: IEpicDeps): Observable<any> => {
  const createResizer$ = (state: IApplicationState) =>
    new Observable<ReturnType<typeof resizeWindow>>((observer) => {
      let resizeTimeout: NodeJS.Timer | null;
      const resizeThrottler = () => {
        const { isMobileUA, isTabletUA } = state.app.device;

        if (!resizeTimeout) {
          resizeTimeout = setTimeout(() => {
            resizeTimeout = null;
            const sizes = getWindowSizes(isMobileUA, isTabletUA);

            observer.next(resizeWindow(sizes));
          }, 500);
        }
      };
      resizeThrottler();
      window.addEventListener('resize', resizeThrottler, false);
    });

  return action$.pipe(
    filter((action) => action.type === 'APP_STARTED'),
    withLatestFrom(deps.state$),
    switchMap(([, state]) => createResizer$(state)),
  );
};

export const windowResizeAndroidScrollToInputEpic = (
  action$: Observable<Action>,
  deps: IEpicDeps,
): Observable<any> => {
  const createResizer$ = (state: IApplicationState) =>
    Observable.create(() => {
      const resizeThrottler = () => {
        const { isAndroid } = state.app.device;

        if (
          isAndroid &&
          (document.activeElement?.tagName === 'INPUT' ||
            document.activeElement?.tagName === 'TEXTAREA')
        ) {
          setTimeout(() => {
            document.activeElement?.scrollIntoView({
              block: 'center',
              inline: 'nearest',
            });
          }, 350);
        }
      };
      resizeThrottler();
      window.addEventListener('resize', resizeThrottler, false);
    });

  return action$.pipe(
    filter((action) => action.type === 'APP_STARTED'),
    withLatestFrom(deps.state$),
    switchMap(([, state]) => createResizer$(state)),
  );
};

export interface QAHelpersWindow extends Window {
  updateDetails: (name: keyof IWedding, value: PartialRecursive<IWedding>) => void;
}

declare let window: QAHelpersWindow;

export const qaHelpersEpic: IEpic<AnyAction, any> = (action$) => {
  const qaHelperInitAction = { type: 'QA_HELPER_INIT' };
  const qaHelper$ = () =>
    new Observable<ReturnType<typeof updateWeddingField> | typeof qaHelperInitAction>(
      (observer) => {
        window.updateDetails = (name: keyof IWedding, value: PartialRecursive<IWedding>) => {
          observer.next(updateWeddingField(name, { [name]: value }));
        };
        observer.next(qaHelperInitAction);
      },
    );
  return action$.pipe(
    ofType('APP_STARTED'),
    switchMap(() => qaHelper$()),
  );
};

export const fetchGroups = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType<Action>(WeddingActionTypes.UPDATE_WEDDING, AuthActionTypes.USER_SETUP_COMPLETED),
    withLatestFrom(state$),
    throttleTime(2000),
    mergeMap(([, state]: [Action, IApplicationState]) => {
      const { groupsLoaded } = state.app;
      if (groupsLoaded) return of();
      const { country } = state.weddings.profile.l10n;
      const promise = Countries._.getById(country).getGroups();
      return from(promise).pipe(
        mergeMap((payload) => of(fetchGroupsSuccess(payload))),
        catchError((error) => of(appError({ error, feature: 'App fetchGroups' }))),
      );
    }),
  );

const globalListeners = [
  ['INIT_WEDDING_LISTENER', 'STOP_WEDDING_LISTENER'],
  ['INIT_SHORTLIST_LISTENER', 'STOP_SHORTLIST_LISTENER'],
  [ChecklistActionTypes.FETCH_TASKS_INITIAL, ChecklistActionTypes.STOP_LISTENER],
  [TaskActionTypes.INIT_TODAY_TASK_LISTENER, TaskActionTypes.STOP_TODAY_TASK_LISTENER],
];

const globalListenersInit = globalListeners.map((action) => ({
  type: action[0],
}));

const globalListenersStop = globalListeners.map((action) => ({
  type: action[1],
}));

export const globalListenersInitEpic = (action$: Observable<Action>, { state$ }: IEpicDeps) =>
  action$.pipe(
    ofType<Action>(WeddingActionTypes.UPDATE_WEDDING, AuthActionTypes.USER_SETUP_COMPLETED),
    withLatestFrom(state$),
    filter(
      ([, state]: [any, IApplicationState]) =>
        !!state.weddings.profile.id && !state.weddings.listenersInitialised,
    ),
    mergeMap(() =>
      of(
        ...globalListenersInit,
        {
          type: WeddingActionTypes.GLOBAL_LISTENERS_INITIALISED,
        },
        {
          type: WeddingActionTypes.WEDDING_PROFILE_LOADED_ANALYTICS,
        },
      ),
    ),
  );

export const globalListenersStopEpic = (action$: Observable<SignOutCompleted>) =>
  action$.pipe(
    ofType(AuthActionTypes.SIGN_OUT_COMPLETED),
    mergeMap(() => of(...globalListenersStop)),
  );
