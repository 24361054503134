import { UserInfo } from 'firebase/auth';
import { omit, pathOr } from 'ramda';
import { ofType } from 'redux-observable';
import { Observable, combineLatest } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { getBaseURL, getSupplierUrl, getUrlCoupleSideLocale } from '@bridebook/toolbox';
import gazetteer, { CountryCodes, Gazetteer } from '@bridebook/toolbox/src/gazetteer';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import { IUISupplier } from '@bridebook/toolbox/src/types';
import { Appsflyer } from '@ionic-native/appsflyer';
import {
  createWeddingLevelIdentify,
  getLocalisation,
  modalPropertiesGeneric,
  testingPropertiesGeneric,
} from 'lib/analytics-utils';
import {
  AuthActionTypes,
  ICompletedEnquiryFormOnLoggedOutPages,
  ITriggeredEnquiryFormOnLoggedOutPages,
} from 'lib/auth/action-types';
import { isCollaboratorInvite } from 'lib/auth/selectors';
import { AuthProviders, IAuthState } from 'lib/auth/types';
import { WebAnalyticsContext } from 'lib/bbcommon/utils/bridebook-analytics';
import { Action, IApplicationState } from 'lib/types';
import { GuestsOnlyUrls } from 'lib/url-helper';
import { ProvidersType } from 'lib/users/types';
import { CriticalWebEvents } from '../analyticsTypes';

declare const window: Window & typeof globalThis & { plugins: any };

const popupType = (auth: IAuthState, open: boolean): { event: string; category: string } => {
  const { signup, resetPassword, passChange, emailExistsWithDifferentProvider, emailExists } = auth;
  const ssoModal = !!emailExistsWithDifferentProvider;
  const signupPage = signup && !passChange && !resetPassword && !ssoModal && !emailExists;
  const resetPassPage = resetPassword;
  const passChangePage = passChange;
  const closeEvent = ssoModal ? 'Closed SSO modal' : 'Closed login modal';
  let popup = {
    event: open ? 'Triggered login modal' : closeEvent,
    category: 'Login',
  };
  if (signupPage) {
    popup = {
      event: open ? 'Triggered registration modal' : 'Closed registration modal',
      category: 'Registration',
    };
  }

  if (resetPassPage || passChangePage) {
    popup = {
      event: open ? 'Triggered reset password modal' : 'Closed reset password modal',
      category: 'Password reset',
    };
  }

  return popup;
};

const filterLabelProps = (props: object): object =>
  omit(['userEmail', 'enteredEmail', 'authProviders', 'collaboratorInvite'], props);

const getAuthProvider = (getState: () => IApplicationState): string => {
  const {
    users: { providers, user },
  } = getState();

  let provider: string | UserInfo = '';
  if (user && user.provider) {
    provider = user.provider;
  }

  const providersArr: UserInfo[] = Object.values(providers);
  providersArr.forEach((p) => {
    if (p.providerId) {
      provider = p.providerId;
    }
  });

  return provider;
};

const SSOPropertiesGeneric = (
  provider?: AuthProviders | null,
): {
  SSOEmailMatch: boolean;
  SSOFacebookMatch: boolean;
  SSOGoogleMatch: boolean;
} => ({
  SSOEmailMatch: provider === AuthProviders.PASSWORD,
  SSOFacebookMatch: provider === AuthProviders.FACEBOOK,
  SSOGoogleMatch: provider === AuthProviders.GOOGLE,
});

interface IUserAuthPropertiesGeneric {
  enteredEmail: string;
  authMethod: string;
}

const userAuthPropertiesGeneric = (
  getState: () => IApplicationState,
  provider?: string,
  fields?: { email: string },
): IUserAuthPropertiesGeneric => {
  const {
    form: {
      fields: { email },
    },
  } = getState().auth;

  return {
    enteredEmail: fields ? fields.email : email,
    authMethod: provider || getAuthProvider(getState),
  };
};

export const authPropertiesGeneric = (
  getState: () => IApplicationState,
): {
  authProviders: ProvidersType;
  emailAuth: boolean;
  facebookAuth: boolean;
  googleAuth: boolean;
} => {
  const {
    users: { providers },
  } = getState();
  return {
    authProviders: providers,
    emailAuth: !!providers[AuthProviders.PASSWORD].providerId,
    facebookAuth: !!providers[AuthProviders.FACEBOOK].providerId,
    googleAuth: !!providers[AuthProviders.GOOGLE].providerId,
  };
};

export const userRegistrationPropertiesGeneric = (
  getState: () => IApplicationState,
): {
  id: string;
  profileType: string;
  userRegistered: boolean;
  userRegistrationDate: string;
  userRegistrationMethod: string;
  userEmail: string;
} => {
  const {
    users: { user },
  } = getState();

  const currentDate = new Date().toISOString();
  return {
    id: user ? user.id : '',
    profileType: 'user',
    userRegistered: true,
    userRegistrationDate: currentDate,
    userRegistrationMethod: getAuthProvider(getState),
    userEmail: pathOr('', ['contacts', 'email'], user),
  };
};

const requestBrochureLoggedOutCommonProps = (
  supplier: IUISupplier,
  getState: () => IApplicationState,
  countryCode: CountryCodes,
  countryName: string,
  contactLocation?: string,
) => {
  const state = getState();
  const {
    app: { previousPath },
    enquiries: { intent },
  } = state;

  const baseUrl = getBaseURL();
  const supplierLocale = getUrlCoupleSideLocale(countryCode);
  const contactedSupplierProfileURL = `${baseUrl}/${supplierLocale}${getSupplierUrl(supplier)}`;

  return {
    category: 'Logged out',
    contactedSupplierCategory: supplier.type,
    contactedSupplierId: supplier.id,
    contactedSupplierName: supplier.name,
    contactedSupplierDescription: supplier.description,
    contactedSupplierCounty: supplier.county || supplier.address?.adminArea[0],
    contactedSupplierCity: supplier.town,
    contactedSupplierProfileURL,
    contactIntent: intent,
    countryName,
    countryCode,
    previousPath,
    contactType: 'singleEnquiry',
    contactLocation,
  };
};

export const authAnalyticsEpic = (action$: Observable<Action>): Observable<any> =>
  action$.pipe(
    ofType(
      AuthActionTypes.SIGN_IN_ERROR,
      // 'SIGN_UP_FAIL',
    ),
    map(({ payload: { error, providerName } }) => ({
      // type: type === 'SIGN_UP_FAIL' ? 'REGISTRATION_ERROR_ANALYTICS' : 'LOGIN_ERROR_ANALYTICS',
      type: 'LOGIN_ERROR_ANALYTICS',
      payload: { error, provider: providerName },
    })),
  );

export const registrationSuccessAnalyticsEpic = (action$: Observable<Action>) => {
  const userSetupCompleted$ = action$.pipe(ofType(AuthActionTypes.USER_SETUP_COMPLETED), take(1));

  const registrationSuccess$ = action$.pipe(ofType('REGISTRATION_SUCCESS_ANALYTICS'), take(1));

  return combineLatest([registrationSuccess$, userSetupCompleted$]).pipe(
    map(([{ payload }]) => ({
      type: 'REGISTRATION_SUCCESS_ANALYTICS_COMBINED',
      payload,
    })),
  );
};

export const loginSuccessAnalyticsEpic = (action$: Observable<any>) => {
  const userSetupCompleted$ = action$.pipe(ofType(AuthActionTypes.USER_SETUP_COMPLETED), take(1));

  const loginSuccess$ = action$.pipe(ofType('LOGIN_SUCCESS_ANALYTICS'), take(1));

  return combineLatest([loginSuccess$, userSetupCompleted$]).pipe(
    map(([{ payload }]) => ({ type: 'LOGIN_SUCCESS_ANALYTICS_COMBINED', payload })),
  );
};

export default function authAnalytics(
  action: Action,
  bridebookAnalytics: WebAnalyticsContext,
  getState: () => IApplicationState,
) {
  const { payload } = action;
  const { track, identifyWithTrack: identify } = bridebookAnalytics.getMethods(
    'Password reset',
    filterLabelProps,
  );

  switch (action.type) {
    case 'LOGIN_SUCCESS_ANALYTICS_COMBINED': {
      const {
        app: { pathname: pageCategoryWhenUserLoggedIn },
      } = getState();
      const { user, provider } = payload;

      try {
        identify(
          { pageCategoryWhenUserLoggedIn, isAnonymous: false },
          {
            event: CriticalWebEvents.LOGGED_IN_AS_USER,
            category: 'Login',
            ...testingPropertiesGeneric(getState),
            ...userAuthPropertiesGeneric(getState, provider),
            ...authPropertiesGeneric(getState),
            pageCategoryWhenUserLoggedIn,
          },
          {
            userId: user.id,
          },
        );
      } catch (e) {
        SentryMinimal().captureException(e, {
          tags: {
            source: 'LoginSuccessAnalytics',
          },
        });
      }
      break;
    }
    case 'LOGIN_ERROR_ANALYTICS': {
      const { fields, error, provider } = payload;
      track({
        event: 'User login failed',
        category: 'Login',
        ...testingPropertiesGeneric(getState),
        ...userAuthPropertiesGeneric(getState, provider, fields),
        reasonUserLoginFailed: error.message,
      });
      break;
    }
    case 'REGISTRATION_SUCCESS_ANALYTICS_COMBINED': {
      const state = getState();
      const {
        app: {
          device: { isCordova },
        },
        auth: {
          form: {
            fields: { countryCode },
          },
        },
      } = state;
      const collaboratorInvite = isCollaboratorInvite(state);
      const { pageCategoryWhenUserRegistered, user, provider } = action.payload;

      const id = user?.id;

      const isFromRequestBrochure = pageCategoryWhenUserRegistered.includes(
        GuestsOnlyUrls.requestBrochure,
      );

      const registrationGeneric = userRegistrationPropertiesGeneric(() => state);
      const market = gazetteer.getMarketByCountry(countryCode);
      const countryName = countryCode ? Gazetteer.getCountryName(countryCode) : undefined;
      if (isCordova) {
        Appsflyer.logEvent('af_complete_registration', {
          af_registration_method: registrationGeneric.userRegistrationMethod,
          af_customer_user_id: id,
          af_currency: market.currency,
        });
      }

      if (id) bridebookAnalytics.alias(id);

      identify(
        {
          collaborator: collaboratorInvite,
          pageCategoryWhenUserRegistered,
          isAnonymous: false,
          ...authPropertiesGeneric(getState),
          ...registrationGeneric,
          ...getLocalisation(state),
          countryCode,
          countryName,
          locale: market.locale,
          marketPrefix: market.prefix,
        },
        {
          event: CriticalWebEvents.REGISTERED_AS_USER,
          category: 'Registration',
          isAnonymous: false,
          ...testingPropertiesGeneric(getState),
          ...userAuthPropertiesGeneric(getState, provider, payload.fields),
          ...authPropertiesGeneric(getState),
          pageCategoryWhenUserRegistered,
          selectedWeddingCountry: countryCode,
          ...getLocalisation(state),
          signupLocation: isFromRequestBrochure ? 'enquiryForm' : 'signupPage',
        },
        { userId: id },
      );

      const serverIdentify = createWeddingLevelIdentify<{ pbStatus: boolean }>(getState);
      serverIdentify({ pbStatus: true });

      break;
    }
    case 'REGISTRATION_ERROR_ANALYTICS': {
      const {
        auth: {
          form: {
            fields: { countryCode },
          },
        },
      } = getState();
      const { error, fields, provider } = payload;
      track({
        event: CriticalWebEvents.USER_REGISTRATION_FAILED,
        category: 'Registration',
        ...testingPropertiesGeneric(getState),
        ...userAuthPropertiesGeneric(getState, provider, fields),
        reasonUserRegistrationFailed: error.message,
        selectedWeddingCountry: countryCode,
        selectedWeddingCountryName: Gazetteer.getCountryName(countryCode),
      });
      break;
    }

    case 'TOGGLE_SSO_MODAL_ANALYTICS': {
      const { matchedProvider, authMethod } = payload;

      track({
        event: 'Triggered SSO modal',
        category: 'Login',
        ...testingPropertiesGeneric(getState),
        ...modalPropertiesGeneric({
          ...payload,
        }),
        ...userAuthPropertiesGeneric(getState, authMethod, {
          email: payload.enteredEmail,
        }),
        ...SSOPropertiesGeneric(matchedProvider),
      });
      break;
    }

    case AuthActionTypes.TOGGLE_AUTH_MODAL_ANALYTICS: {
      const {
        auth,
        auth: { emailExistsWithDifferentProvider },
      } = getState();
      const ssoModal = !!emailExistsWithDifferentProvider;
      const ssoProps =
        ssoModal && payload.open ? SSOPropertiesGeneric(emailExistsWithDifferentProvider) : {};
      track({
        ...popupType(auth, payload.open),
        ...ssoProps,
        ...testingPropertiesGeneric(getState),
        ...modalPropertiesGeneric(payload),
      });
      break;
    }

    case 'FIREBASE_RESET_PASSWORD_SUCCESS_ANALYTICS': {
      const { fields } = payload;
      track({
        event: 'User requested password change',
        enteredEmail: fields.email,
      });
      break;
    }

    case 'FIREBASE_RESET_PASSWORD_ERROR_ANALYTICS': {
      const { fields, error } = payload;
      track({
        event: 'User password change request failed',
        enteredEmail: fields.email,
        reasonUserFailedPasswordReset: error.message,
      });
      break;
    }

    case 'CHANGE_PASSWORD_SUCCESS_ANALYTICS': {
      const { fields } = payload;
      track({
        event: 'User completed password change',
        enteredEmail: fields.email,
      });
      break;
    }

    case 'CHANGE_PASSWORD_ERROR_ANALYTICS': {
      const { fields, error } = payload;
      track({
        event: 'User password change completion failed',
        enteredEmail: fields.email,
        reasonUserFailedPasswordReset: error.message,
      });
      break;
    }

    case 'LOGOUT_ANALYTICS': {
      const {
        app: { pathname },
        users,
      } = getState();
      const email = users.user?.contacts?.email;
      track({
        event: 'User logged out',
        category: 'Logout',
        userEmail: email,
        pageCategoryWhenUserLoggedOut: pathname || '/',
      });
      break;
    }

    case 'USER_UPDATED_AUTH_DETAILS_SUCCESS_ANALYTICS': {
      const { fields, provider } = payload;
      identify(
        { ...authPropertiesGeneric(getState) },
        {
          event: 'User updated auth details',
          category: 'SSO',
          ...authPropertiesGeneric(getState),
          ...userAuthPropertiesGeneric(getState, provider, fields),
        },
        { userId: getState().users.user?.id },
      );
      break;
    }

    case 'USER_UPDATED_AUTH_DETAILS_ERROR_ANALYTICS': {
      const { fields, provider, error } = payload;
      track({
        event: 'User failed to update auth details',
        category: 'SSO',
        ...authPropertiesGeneric(getState),
        ...userAuthPropertiesGeneric(getState, provider, fields),
        reasonUserFailedToUpdateAuthDetails: error.message,
      });
      break;
    }

    case AuthActionTypes.VIEWED_SIGNUP_PAGE_ANALYTICS: {
      const { selectedWeddingCountry } = payload;

      const state = getState();
      const {
        app: {
          device: { isDesktop: mobileApp },
          pathname,
        },
      } = state;

      let hasAnyDirectory = false;

      if (selectedWeddingCountry) {
        hasAnyDirectory = gazetteer.getMarketByCountry(selectedWeddingCountry).hasAnyDirectory;
      }

      const liteVersion = selectedWeddingCountry ? !hasAnyDirectory : null;

      track({
        event: 'Viewed signup page',
        category: 'Logged out',
        selectedWeddingCountry,
        path: pathname,
        mobileApp,
        liteVersion,
      });
      break;
    }

    case AuthActionTypes.TRIGGERED_ENQUIRY_FORM_ON_LOGGED_OUT_PAGE: {
      const { supplier, countryName, countryCode, contactLocation } =
        payload as ITriggeredEnquiryFormOnLoggedOutPages['payload'];

      track({
        event: 'Triggered enquiry form on logged out page',
        ...requestBrochureLoggedOutCommonProps(
          supplier,
          getState,
          countryCode,
          countryName,
          contactLocation,
        ),
      });
      break;
    }

    case AuthActionTypes.COMPLETED_ENQUIRY_FORM_ON_LOGGED_OUT_PAGE: {
      const { supplier, countryName, countryCode, contactLocation } =
        payload as ICompletedEnquiryFormOnLoggedOutPages['payload'];

      track({
        event: 'Completed enquiry form on logged out page',
        ...requestBrochureLoggedOutCommonProps(
          supplier,
          getState,
          countryCode,
          countryName,
          contactLocation,
        ),
      });
      break;
    }
    default:
      break;
  }
}
