import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { getPathname } from 'lib/app/selectors';
import { AuthActionTypes } from 'lib/auth/action-types';
import { updateSearchCategory } from 'lib/search/actions';
import { isSearchResultsPage } from 'lib/search/utils';
import { Action, IEpicDeps } from 'lib/types';
import { WeddingActionTypes } from 'lib/weddings/action-types';
import { getVenueBooked } from 'lib/weddings/selectors';

/**
 * When wedding profile is loaded or updated from firebase,
 check if venue is booked recently, if it is,
 then set default category as 'photo', otherwise set is as 'venue
 */
export const updateSearchbarCategoryOnProfileChangeEpic = (
  action$: Observable<Action>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType<Action>(WeddingActionTypes.UPDATE_WEDDING, AuthActionTypes.USER_SETUP_COMPLETED),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const {
        app: {
          device: { isMobile, isTablet },
        },
        search: {
          request: { slug },
          request,
        },
      } = state;
      const venueBooked = getVenueBooked(state);
      const currentPath = getPathname(state);
      const nextCategory = venueBooked && request.slug !== 'venue' ? 'photo' : 'venue';
      if (nextCategory !== slug && (isMobile || isTablet)) {
        return of(updateSearchCategory(nextCategory, isSearchResultsPage(currentPath)));
      }
      return of();
    }),
  );
