import { ofType } from 'redux-observable';
import { Observable, from, of } from 'rxjs';
import { catchError, filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import { TranslatedError } from '@bridebook/toolbox/src';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { appError } from 'lib/app/actions';
import { getI18n } from 'lib/i18n/getI18n';
import { fetchSupplierCollaboration } from 'lib/shortlist/actions';
import { IEpicDeps } from 'lib/types';
import { ShortlistActionTypes, ToggleSupplierCollaborationAction } from '../action-types';

export const toggleSupplierCollaborationEpic = (
  action$: Observable<ToggleSupplierCollaborationAction>,
  { state$ }: IEpicDeps,
) =>
  action$.pipe(
    ofType(ShortlistActionTypes.TOGGLE_SUPPLIER_COLLABORATION),
    withLatestFrom(state$),
    filter(([action]) => {
      const { supplier } = action.payload;

      return (
        (supplier.slug || supplier.type) === 'venue' && supplier.countryCode === CountryCodes.GB
      );
    }),
    mergeMap(([action, state]) => {
      const { supplier, isCollaborating } = action.payload;
      const {
        users: { user },
        weddings: {
          profile: { id: profileId },
        },
        shortlist: { collaborationLoaded },
      } = state;

      const userId = user?.id;
      const i18n = getI18n();
      const promise = authenticatedFetch(`/api/supplier-collaboration/toggle`, {
        method: 'POST',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          profileId,
          userId,
          collaborating: isCollaborating,
          supplierId: supplier.id,
        }),
      }).then((res) => {
        if (res.status !== 200) {
          throw new TranslatedError(
            'Error toggling supplier collaborating',
            i18n.t('shortlist:error.toggleSupplier'),
          );
        }

        return res.json();
      });

      const actions: Record<string, any>[] = [];
      // Collab confirmation popup moved to discover-venue-modal
      // if (isCollaborating) {
      //   actions.push(showConnectedWithVenuePopup());
      // }
      // Re-fetch collaboration only if was loaded before
      if (collaborationLoaded) {
        actions.push(fetchSupplierCollaboration(supplier.id));
      }

      return from(promise).pipe(
        mergeMap(() => of(...actions)),
        catchError((error) => of(appError({ error, feature: 'Shortlist' }))),
      );
    }),
  );
