import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { EnquiryIntents, IUISupplier } from '@bridebook/toolbox/src/types';
import { EnquiriesActionTypes } from 'lib/enquiries/action-types';
import { sendEnquirySuccessAnalytics } from 'lib/enquiries/analytics/actions';
import enquiriesServerSideTrack from 'lib/enquiries/utils/enquiries-server-side-track';
import { filterLabelProps } from 'lib/shortlist/analytics';
import { isBudgetMatch } from 'lib/supplier/utils/budget-match';
import { Action, IApplicationState } from 'lib/types';
import { CriticalWebEvents } from '../../analyticsTypes';
import { enquiryCountClosedAnalytics, enquiryCountViewedAnalytics } from '../actions';

interface IEnquiryProperties {
  contactMessage: string;
  contactMethod: string;
  contactLocation: string;
  contactActionLabel: string;
  contactLocationType: string;
  contactIntent: EnquiryIntents;
  contactGalleryCTA?: number | 'end';
  budgetMatch?: boolean;
  inbox?: boolean;
  supplierTier?: number;
  contactType: 'multipleEnquiry' | 'singleEnquiry';
  contactSection?: string;
  cta?: string;
}

export const enquiryPropertiesGeneric = ({
  getState,
  supplier,
  contactLocation = '',
  contactActionLabel = '',
  contactLocationType = '',
  contactGalleryCTA,
  contactSection,
  cta,
}: {
  getState: () => IApplicationState;
  supplier?: IUISupplier | ISupplier | null;
  contactLocation?: string;
  contactActionLabel?: string;
  contactLocationType?: string;
  contactGalleryCTA?: number | 'end';
  contactSection?: string;
  cta?: string;
}): IEnquiryProperties => {
  const {
    weddings: {
      profile: { budget },
    },
    enquiries: {
      multipleEnquiry,
      intent: contactIntent,
      enquiryForm: { fields },
      userEnquiryAnalyticsContactGalleryCTA,
    },
  } = getState();

  const isSupplierProps = supplier && !multipleEnquiry;

  return {
    contactMessage: fields ? fields.message || '' : '',
    contactMethod:
      contactSection === 'showroundWidgetCalendlyInstantBooking'
        ? 'calendlyForm'
        : 'fullContactForm',
    contactLocation,
    contactActionLabel,
    contactLocationType,
    contactIntent,
    contactGalleryCTA:
      contactGalleryCTA != null ? contactGalleryCTA : userEnquiryAnalyticsContactGalleryCTA,
    budgetMatch: isSupplierProps ? isBudgetMatch(budget, supplier) : undefined,
    contactType: multipleEnquiry ? 'multipleEnquiry' : 'singleEnquiry',
    contactSection,
    cta,
  };
};

export const multipleEnquiryPropertiesGeneric = (suppliers: IUISupplier[]) => ({
  supplierCategory: suppliers[0].type,
  multipleEnquiryCount: suppliers.length,
});

export default function enquiriesAnalytics(
  { type, payload }: Action,
  bridebookAnalytics: any,
  getState: () => IApplicationState,
): void {
  const { track } = bridebookAnalytics.getMethods('Enquiries', filterLabelProps);
  const state = getState();

  switch (type) {
    case EnquiriesActionTypes.SEND_ENQUIRY_SUCCESS_ANALYTICS: {
      const {
        id,
        contactedSupplier,
        inboxMessageSent,
        isFromExternalPage,
        contactLocation,
        contactType,
        contactMethod,
        linkedSupplierName,
        linkedSupplierId,
      } = payload as ReturnType<typeof sendEnquirySuccessAnalytics>['payload'];

      enquiriesServerSideTrack({
        event: CriticalWebEvents.EMAILED_SUPPLIER,
        contactedSupplier,
        state,
        id,
        inboxMessageSent,
        infoProps: { method: contactLocation },
        ...(isFromExternalPage ? { infoProps: { method: 'supplierWebsiteCTA' } } : {}),
        ...(contactType && { contactType }),
        ...(contactMethod && { contactMethod }),
        ...(linkedSupplierName && { linkedSupplierName }),
        ...(linkedSupplierId && { linkedSupplierId }),
      });
      break;
    }

    case EnquiriesActionTypes.ENQUIRY_COUNT_VIEWED_ANALYTICS: {
      const { location, messageContent, messageType } = payload as ReturnType<
        typeof enquiryCountViewedAnalytics
      >['payload'];

      track({
        event: 'Viewed urgent message',
        location,
        messageContent,
        messageType,
      });
      break;
    }

    case EnquiriesActionTypes.ENQUIRY_COUNT_CLOSED_ANALYTICS: {
      const { location, messageContent, messageType } = payload as ReturnType<
        typeof enquiryCountClosedAnalytics
      >['payload'];

      track({
        event: 'Clicked to dismiss urgent message',
        location,
        messageContent,
        messageType,
      });
      break;
    }

    default:
      break;
  }
}
