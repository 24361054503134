import { bookingEpicCreator } from 'lib/shortlist/utils/booking-creator';
import { ShortlistActionTypes, ShortlistBookedSupplierAction } from '../action-types';

export const shortlistBookSupplierEpic = bookingEpicCreator<ShortlistBookedSupplierAction>({
  actionType: ShortlistActionTypes.BOOK_SUPPLIER,
  analyticsType: ShortlistActionTypes.BOOKED_SUPPLIER_ANALYTICS,
  auditTime: 500,
  updateCreator: (item) => {
    // FIXME: seems to be wrong place for DOM action
    const bookedContainer = document.querySelector('[data-name="category-drawer-container"]');

    if (bookedContainer) {
      bookedContainer.scrollTop = 0;
    }

    return {
      ...item,
      booked: true,
    };
  },
});
