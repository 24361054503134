import fetch from 'isomorphic-fetch';
import Router from 'next/router';
import { contains, findIndex, propEq, uniq } from 'ramda';
import { ISupplier } from '@bridebook/models/source/models/Suppliers.types';
import { ISupplierAdmin } from '@bridebook/models/source/models/Suppliers/Admin.types';
import { IBrochure } from '@bridebook/models/source/models/Suppliers/Brochures.types';
import { IPhoto } from '@bridebook/models/source/models/Suppliers/Photos.types';
import { IVideo } from '@bridebook/models/source/models/Suppliers/Videos.types';
import { ValidationError, getSupplierUrl } from '@bridebook/toolbox/src';
import { authenticatedFetch } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import type { RatingResponse } from '@bridebook/toolbox/src/supplier/getReviewsRating';
import { IUISupplier, Slug } from '@bridebook/toolbox/src/types';
import { IS3File } from '@bridebook/ui';
import { formatReviewFormError } from 'app-shared/lib/reviews/utils';
import { ISupplierData, ISupplierPhoto, Video } from 'app-shared/lib/supplier/supplier-types';
import {
  IFeedbackSerialized,
  SupplierCardInView,
  TRefSupplier,
  ViewedEndScreen,
} from 'app-shared/lib/supplier/types';
import { TGetSupplierStatisticsResponse } from 'pages/api/supplier/[id]/statistics';
import { ApiEndpoint } from 'lib/api/api-endpoint';
import { toggleSnackbar } from 'lib/bbcommon/actions';
import { scrollToTop } from 'lib/bbcommon/utils/animations';
import { enquiryFormToggle, setEnquirySupplier, setInfoProps } from 'lib/enquiries/actions';
import { env } from 'lib/env';
import { getI18n } from 'lib/i18n/getI18n';
import { format } from 'lib/i18n/utils/get-date-fns-format';
import { OfferTypes } from 'lib/offers/constants';
import { SearchActionTypes } from 'lib/search/action-types';
import { getSearchList } from 'lib/search/selectors';
import { buildUISupplier } from 'lib/shortlist/utils';
import { ISupplierQueryParams } from 'lib/supplier/hooks/query/use-supplier';
import { getYesterday } from 'lib/supplier/utils';
import { TrackingEvent } from 'lib/track-utils/tracking-event';
import {
  EnquiryFormInfoProps,
  IDeps,
  MoodBoardMediaType,
  ReviewFormFields,
  WatchedSupplierVideoLocations,
} from 'lib/types';
import { toggleSpecialOfferPopup } from 'lib/ui/actions';
import { noopAction } from 'lib/utils';
import { assertState } from 'lib/utils/assertState';
import openInBrowser from 'lib/utils/open-in-browser';
import { IOfferSerialized } from '../offers/types';
import { IViewedSupplierAnalyticsAction, SupplierActions } from './actions-types';
import { getCurrentSupplier, getCurrentSupplierPhotosCount, getSupplierPhotos } from './selectors';
import { IFeedbackResponse, SupplierCategory } from './types';

export const toggleSupplierAccordion =
  (
    name: string,
    opened: boolean = false,
    method: string | null = 'accordion',
    ui: boolean = true,
  ) =>
  ({ getState, dispatch }: IDeps) => {
    const openedList = [...getState().supplier.accordions];
    const open = opened || !contains(name, openedList);

    if (open) {
      openedList.push(name);
      // analytics
      const section = name.replace('-accordion', '');
      if (ui) {
        dispatch({
          type: SupplierActions.REVEALED_MORE_CONTENT_ON_SUPPLIER_PROFILE_ANALYTICS,
          payload: { method, section },
        });
      }
    } else {
      const index = openedList.indexOf(name);
      openedList.splice(index, 1);
    }

    return {
      type: SupplierActions.TOGGLE_SUPPLIER_ACCORDION,
      payload: uniq(openedList),
    };
  };

export const clickShowMoreReview = (review: Partial<IFeedbackSerialized>) => ({
  payload: {
    selectedReview: review.id,
  },
  type: SupplierActions.CLICKED_SHOW_MORE_REVIEW,
});

export const toggleSupplierReviewsPage =
  (show: boolean, selectedReview?: string | null) =>
  ({ dispatch }: IDeps) => {
    if (show) {
      if (selectedReview) {
        dispatch({
          type: SupplierActions.CLICKED_SEE_ALL_REVIEWS,
        });
      } else {
        dispatch({
          type: SupplierActions.CLICKED_SEE_REVIEW,
          payload: {
            selectedReview,
          },
        });
      }
    }

    return {
      type: SupplierActions.SUPPLIER_REVIEWS_TOGGLE,
      payload: {
        show,
        selectedReview: selectedReview || null,
      },
    };
  };

export const viewedSupplierAnalytics =
  (supplier: ISupplier, refSupplier?: TRefSupplier) =>
  ({ dispatch }: IDeps) => {
    const asyncAction = async () => {
      const supplierStatisticsApiResponse =
        await authenticatedFetch<TGetSupplierStatisticsResponse>(
          `/api/supplier/${supplier.id}/statistics`,
        );

      const supplierStatisticsApiResponseBody = await supplierStatisticsApiResponse.json();
      const supplierStatistics = supplierStatisticsApiResponseBody.result;
      const analyticsSupplierStatistics = supplierStatistics
        ? {
            otherEnquiriesTotal: supplierStatistics.otherEnquiriesTotal,
            otherViewsTotal: supplierStatistics.otherViewsTotal,
          }
        : { otherEnquiriesTotal: 0, otherViewsTotal: 0 }; // if analytics not found set to 0

      dispatch<IViewedSupplierAnalyticsAction>({
        type: SupplierActions.VIEWED_SUPPLIER_PROFILE_ANALYTICS,
        payload: { supplier, refSupplier, ...analyticsSupplierStatistics },
      });
    };
    asyncAction();
    return noopAction();
  };

export const fetchFeedbackPromise = (
  id: string,
  page = 1,
  itemsOnPage = 0,
  locale?: string,
): Promise<IFeedbackResponse> =>
  authenticatedFetch(
    `/api/supplier/feedback/${id}/${page}${locale ? '/' + locale : ''}${
      itemsOnPage ? '/' + itemsOnPage : ''
    }`,
  ).then((d) => d.json());

export const fetchSupplierData = (publicId: string, locale?: string): Promise<ISupplierData> =>
  authenticatedFetch(ApiEndpoint.supplier.data(publicId, locale)).then((d) => d.json());

export const fetchSupplierReviewRating = (id: string): Promise<RatingResponse> =>
  authenticatedFetch(`/api/supplier/${id}/statistics/reviews-rating`).then((d) => d.json());

export const fetchFeedbackDone = (payload: IFeedbackResponse) => ({
  type: SupplierActions.FETCH_FEEDBACK_DONE,
  payload,
});

export const fetchSupplierStart = (query: ISupplierQueryParams) => ({
  type: SupplierActions.FETCH_SUPPLIER_START,
  payload: { query },
});

export const fetchSupplierError = (errorCode: number) => () => ({
  type: SupplierActions.FETCH_SUPPLIER_ERROR,
  payload: { errorCode },
});

export const setSupplierDeleted = (deleted?: boolean) => ({
  type: SupplierActions.SET_SUPPLIER_DELETED,
  payload: { deleted },
});

export const fetchSupplierSuccess =
  (supplier: ISupplier, reviewsPage: boolean = false, refSupplier?: TRefSupplier) =>
  ({ dispatch, getState, cordovaTracker }: IDeps) => {
    const {
      app: {
        device: { isMobile },
      },
    } = getState();

    const asyncAction = async () => {
      dispatch({
        type: SupplierActions.FETCH_SUPPLIER_SUCCESS,
        payload: { supplier },
      });
      if (!isMobile && !reviewsPage) {
        dispatch(toggleSupplierAccordion('faq-accordion', true, undefined, false));
      }

      if (!reviewsPage) {
        cordovaTracker.track(TrackingEvent.ViewedSupplierProfile, supplier);
        dispatch(viewedSupplierAnalytics(supplier, refSupplier));
      }
    };
    asyncAction();
    return noopAction();
  };

export function gotoNextSupplier() {
  return ({ getState, dispatch }: IDeps) => {
    const state = getState();
    const list = getSearchList(state);
    const supplierId = getCurrentSupplier(state)?.id;
    const index = findIndex(propEq('id', supplierId), list);
    const nextSupplier = list[index + 1];

    if (nextSupplier) {
      Router.push(getSupplierUrl(nextSupplier)).then(() => {
        scrollToTop('auto');
      });
    }

    dispatch({
      type: SupplierActions.CLICKED_NEXT_SUPPLIER_PROFILE_ANALYTICS,
      payload: { nextSupplier },
    });

    return {
      type: SupplierActions.GOTO_NEXT_SUPPLIER,
    };
  };
}

export interface FetchSupplierPromiseQuery {
  id: string;
  supplier?: string;
  slug: Slug;
}

export const clickedSupplierSocialMediaAnalytics = (method: string) => ({
  type: SupplierActions.CLICKED_SUPPLIER_SOCIAL_MEDIA_ANALYTICS,
  payload: { method },
});

export const usedQuickNavigationAnalytics = (method: string, section: string) => ({
  type: SupplierActions.USED_QUICK_NAVIGATION_ON_SUPPLIER_PROFILE_ANALYTICS,
  payload: { method, section },
});

export const revealedMoreContentAnalytics = (method: string, section: string) => ({
  type: SupplierActions.REVEALED_MORE_CONTENT_ON_SUPPLIER_PROFILE_ANALYTICS,
  payload: { method, section },
});

export const scrolledSupplierMediaGalleryAnalytics = () => ({
  type: SupplierActions.SCROLLED_SUPPLIER_MEDIA_GALLERY_ANALYTICS,
});

export const toggledSupplierMediaGalleryAnalytics = (
  isGalleryOpened: boolean,
  toggledFrom?: string,
) => ({
  type: SupplierActions.TOGGLED_SUPPLIER_MEDIA_GALLERY_ANALYTICS,
  payload: { isGalleryOpened, toggledFrom },
});

export const viewedMoodBoardAnalytics = (mediaType: MoodBoardMediaType) => ({
  type: SupplierActions.VIEWED_MOOD_BOARD_ANALYTICS,
  payload: { mediaType },
});

export const viewedEndScreenOnSupplierCarouselAnalytics = ({
  imagesCount,
  location,
  firstImageUrl,
}: ViewedEndScreen) => ({
  type: SupplierActions.VIEWED_END_SCREEN_ON_SUPPLIER_CAROUSEL_ANALYTICS,
  payload: { imagesCount, location, firstImageUrl },
});

export const usedSearchCarouselAnalytics = ({
  numberOfPhotos,
  location,
  supplier,
}: {
  numberOfPhotos: number;
  location: 'search' | 'supplierProfile';
  supplier: IUISupplier;
}) => ({
  type: SearchActionTypes.USED_SEARCH_CAROUSEL_ANALYTICS,
  payload: { numberOfPhotos, location, supplier },
});

export const resetReviewForm = () => () => ({
  type: SupplierActions.RESET_REVIEW_FORM,
});

export const toggleReviewForm =
  (show: boolean, method?: string) =>
  ({ dispatch, getState }: IDeps) => {
    if (!show) {
      dispatch(resetReviewForm());
    }

    const {
      weddings: { profile },
    } = getState();
    let weddingDate: string = profile.weddingDate || '';

    // extra check for future date, sets date to yesterday if it's future or today
    if (new Date(weddingDate) > getYesterday()) {
      weddingDate = format(getYesterday(), 'yyyy-MM-dd');
    }

    return {
      type: SupplierActions.TOGGLE_REVIEW_FORM,
      payload: { show, weddingDate, method },
    };
  };

export interface IReviewFormFieldEvent {
  target: {
    name: keyof ReviewFormFields;
    value: ReviewFormFields[keyof ReviewFormFields];
  };
}

export const setReviewFormField = ({ target: { name, value } }: IReviewFormFieldEvent) => ({
  type: SupplierActions.SET_REVIEW_FORM_FIELD,
  payload: { name, value },
});

export const setReviewFormRating = (stars: number) => () => ({
  type: SupplierActions.SET_REVIEW_FORM_RATING,
  payload: stars,
});

export const hideReviewFormError = () => () => ({
  type: SupplierActions.HIDE_REVIEW_FORM_ERROR,
});

export const reviewFormRecaptchaVerified = () => ({
  type: SupplierActions.REVIEW_FORM_RECAPTCHA_VERIFIED,
});

export const saveReview = () => ({
  type: SupplierActions.SAVE_REVIEW,
});

export const toggleSnackbarSuccess = () =>
  toggleSnackbar('success', getI18n().t('supplier:snackbar.reviewAdded'));

export const saveReviewSuccess =
  () =>
  ({ dispatch }: IDeps) => {
    dispatch({ type: SupplierActions.SUBMITTED_REVIEW_ANALYTICS });
    dispatch(toggleReviewForm(false));

    setTimeout(() => {
      dispatch(toggleSnackbarSuccess());
    }, 1000);

    return {
      type: SupplierActions.SAVE_REVIEW_SUCCESS,
    };
  };

export const saveReviewError =
  (error: ValidationError) =>
  ({ dispatch }: IDeps) => {
    const i18n = getI18n();
    const err = formatReviewFormError(error, i18n.t);

    dispatch({
      type: SupplierActions.FAILED_TO_SUBMIT_REVIEW_ANALYTICS,
      payload: { message: err.message },
    });

    return {
      type: SupplierActions.SAVE_REVIEW_ERROR,
      payload: err,
    };
  };

export const uploadReviewPhoto = (photo: IS3File) => ({
  type: SupplierActions.UPLOAD_REVIEW_PHOTO,
  payload: { photo },
});

export const uploadReviewPhotoSuccess = (photo: Partial<ISupplierPhoto>) => ({
  type: SupplierActions.UPLOAD_REVIEW_PHOTO_SUCCESS,
  payload: photo,
});

export const uploadReviewPhotoError = (err: Error) => ({
  type: SupplierActions.UPLOAD_REVIEW_PHOTO_ERROR,
  payload: err,
});

export const resetSupplierAccordion = () => ({
  type: SupplierActions.RESET_SUPPLIER_ACCORDION,
});

export const fetchFeedback = (page: number = 1) => ({
  type: SupplierActions.FETCH_FEEDBACK,
  payload: page,
});

export const supplierGalleryLoadPhotos =
  () =>
  ({ dispatch, getState }: IDeps) => {
    const state = getState();
    const supplier = getCurrentSupplier(state);
    const photos = getSupplierPhotos(state);
    const photosCount = getCurrentSupplierPhotosCount(state);

    if (supplier?.id && photos && photos.length < photosCount && photosCount > 1) {
      dispatch(fetchSupplierPhotos(supplier.id));
    }

    return {
      type: SupplierActions.SUPPLIER_GALLERY_LOAD_PHOTOS,
      payload: false,
    };
  };

export const supplierGalleryToggle =
  (index: number = 0, toggledFrom?: string) =>
  ({ getState, dispatch }: IDeps) => {
    dispatch(supplierGalleryLoadPhotos());

    const show = !getState().supplier.showGallery;

    if (show) {
      dispatch(toggledSupplierMediaGalleryAnalytics(true, toggledFrom));
    }

    return { type: SupplierActions.SUPPLIER_GALLERY_TOGGLE, payload: { show, index } };
  };

export const clickedUpNextTileAnalytics = (supplier: IUISupplier) => ({
  type: SupplierActions.CLICKED_UP_NEXT_VENUE_ANALYTICS,
  payload: { supplier },
});

export const supplierVideoModalToggle =
  (open: boolean = false, video?: Video | IVideo) =>
  ({ dispatch }: IDeps) => {
    if (open && video) {
      dispatch({
        type: SupplierActions.WATCHED_SUPPLIER_VIDEO_ANALYTICS,
        payload: { video },
      });
    }

    return {
      type: SupplierActions.SUPPLIER_VIDEO_MODAL_TOGGLE,
      payload: open,
    };
  };

interface IWatchedSupplierVideoProps {
  viewLocation: WatchedSupplierVideoLocations;
  mediaType: MoodBoardMediaType;
}

export const watchedSupplierVideoAnalytics = (
  video: Video | IVideo,
  { viewLocation, mediaType }: IWatchedSupplierVideoProps,
) => ({
  type: SupplierActions.WATCHED_SUPPLIER_VIDEO_ANALYTICS,
  payload: { video, viewLocation, mediaType },
});

export const redeemSupplierSpecialOffer =
  ({ type, offer }: { type?: OfferTypes; offer?: IOfferSerialized }) =>
  ({ dispatch, getState }: IDeps) => {
    const supplier = getState().supplier.supplier;
    dispatch(toggleSpecialOfferPopup({ offer: null, type: undefined }));

    assertState(supplier, 'loaded', 'supplier');

    setTimeout(() => {
      dispatch(setEnquirySupplier({ supplier: buildUISupplier(supplier.data) }));
      const infoProps: EnquiryFormInfoProps = {
        locationType: 'supplierProfileSpecialOffer',
        method: 'supplierProfile_specialOffer',
        defaultIntent: type === OfferTypes.lateAvailability ? 'availability' : 'pricing',
      };
      dispatch(
        setInfoProps({
          method: infoProps.method,
          locationType: infoProps.locationType,
        }),
      );

      dispatch(
        enquiryFormToggle({
          show: true,
          infoProps,
        }),
      );
    }, 50);

    return {
      type: SupplierActions.REDEEM_SUPPLIER_SPECIAL_OFFER,
      payload: { type, offer },
    };
  };

export const downloadedSupplierBrochureAnalytics = (interactedSupplierBrochureLink: string) => ({
  type: SupplierActions.DOWNLOADED_SUPPLIER_BROCHURE_ANALYTICS,
  payload: { interactedSupplierBrochureLink },
});

export const openSupplierBrochure =
  (brochure: IBrochure) =>
  ({ getState, dispatch }: IDeps) => {
    const { isCordova, isMobileSafari } = getState().app.device;
    const brochureId = brochure.id;
    const brochureFile = brochure.file;
    const brochureUrl = `${env.S3_URL}/${brochureId}`;
    const brochureFileName = brochureFile;

    if (isCordova || isMobileSafari) {
      openInBrowser(brochureUrl);
      // window.open(brochureUrl, isCordova ? '_system' : '_blank');
      dispatch(downloadedSupplierBrochureAnalytics(brochureUrl));
    } else {
      fetch(brochureUrl)
        .then((d) => d.blob())
        .then((blob) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = brochureFileName;
          link.click();
          link.remove();
          dispatch(downloadedSupplierBrochureAnalytics(brochureUrl));
        });
    }

    return { type: SupplierActions.DOWNLOAD_SUPPLIER_BROCHURE };
  };

export const supplierCardInView = ({
  supplier,
  supplierTileOrder,
  viewedLocation,
  refSupplier,
}: SupplierCardInView) => ({
  type: SupplierActions.SUPPLIER_CARD_IN_VIEW,
  payload: {
    supplier,
    supplierTileOrder,
    viewedLocation,
    refSupplier,
  },
});

export const toggleWhyBridebookModal =
  (show: boolean = false) =>
  ({ dispatch }: IDeps) => {
    dispatch(toggleWhyBridebookModalAnalytics(show));

    return {
      type: SupplierActions.TOGGLE_WHY_BRIDEBOOK_MODAL,
      payload: show,
    };
  };

export const toggleWhyBridebookModalAnalytics = (show: boolean) => ({
  type: SupplierActions.TOGGLE_WHY_BRIDEBOOK_MODAL_ANALYTICS,
  payload: show,
});

export const fetchPremiumDone = (premium: ISupplierAdmin['premium']) => ({
  type: SupplierActions.FETCH_PREMIUM_DONE,
  payload: { premium },
});

export const fetchSupplierPhotos = (supplierId: string) => ({
  type: SupplierActions.FETCH_PHOTOS,
  payload: { supplierId },
});

export const fetchSupplierPhotosDone = (photos: IPhoto[]) => ({
  type: SupplierActions.FETCH_PHOTOS_DONE,
  payload: { photos },
});

export const fetchSupplierDataDone = ({
  appsConnected,
  premium,
  offers,
  badges,
  packages,
  videos,
  brochures,
  achievements,
  recommendations,
  questions,
  fairs,
  seoIndex = true,
  relations,
  hasBadDebtCoupleside,
}: Omit<ISupplierData, 'supplierPackages' | 'photos'>) => ({
  type: SupplierActions.FETCH_SUPPLIER_DATA_DONE,
  payload: {
    appsConnected,
    premium,
    offers,
    badges,
    packages,
    videos,
    brochures,
    achievements,
    recommendations,
    questions,
    fairs,
    seoIndex,
    relations,
    hasBadDebtCoupleside,
  },
});

export const supplierAndCoupleCollaboratingAnalytics = ({
  collaboratingSupplierId,
  collaborationTriggerLocation,
  started = true,
}: {
  collaboratingSupplierId: string;
  collaborationTriggerLocation: 'onboarding' | 'shortlist' | 'CMS';
  started?: boolean;
}) => ({
  type: SupplierActions.SUPPLIER_AND_COUPLE_COLLABORATING_ANALYTICS,
  payload: {
    collaboratingSupplierId,
    collaborationTriggerLocation,
    started,
  },
});

export const sharedSupplierProfileLinkAnalytics = (payload: {
  url: string;
  sharedLocation: 'supplierProfile' | 'supplierProfile_photo';
  appChosen?: string;
  supplier: IUISupplier | ISupplier;
}) => ({
  type: SupplierActions.SHARED_SUPPLIER_PROFILE_LINK_ANALYTICS,
  payload,
});

export const clickedSupplierCategoryTagsAnalytics = (payload: {
  supplierCategory: SupplierCategory;
}) => ({
  type: SupplierActions.CLICKED_SUPPLIER_CATEGORY_TAGS_ANALYTICS,
  payload,
});

export const mapInViewAnalytics = () => ({
  type: SupplierActions.MAP_IN_VIEW,
});

export const mapMouseUpdAnalytics = () => ({
  type: SupplierActions.MAP_MOUSE_UP,
});

export const instagramGalleryViewedAnalytics = () => ({
  type: SupplierActions.INSTAGRAM_GALLERY_VIEWED_ANALYTICS,
});

export const instagramGalleryScrolledAnalytics = () => ({
  type: SupplierActions.INSTAGRAM_GALLERY_SCROLLED_ANALYTICS,
});

//Clicked to go to message

export interface IClickedToGoToMessageAnalytics {
  clickedLocation: 'search' | 'supplierProfile' | 'favourites';
  clickedSection: string;
  cta: string;
  supplier?: IUISupplier;
}
export const clickedGoToMessageAnalytics = (payload: IClickedToGoToMessageAnalytics) => ({
  type: SupplierActions.CLICKED_GO_TO_MESSAGE_ANALYTICS,
  payload,
});
