import type { NextApiRequest, NextPageContext } from 'next/types';
import { ComponentType, SyntheticEvent } from 'react';
import { InferableComponentEnhancerWithProps } from 'react-redux';
import { AnyAction, Dispatch, Store } from 'redux';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { Weddings } from '@bridebook/models';
import { ValidationError } from '@bridebook/toolbox/src';
import type { Action, IUISupplier, Slug, UrlFriendlySlug } from '@bridebook/toolbox/src/types';
import { ISearchPageQuery } from 'app-shared/lib/search/types';
import { AutocompletePredictionsService } from 'app-shared/lib/search/utils/autocomplete-predictions-service';
import { GooglePlacesService } from 'app-shared/lib/search/utils/google-places-service';
import { TQuizId } from 'components/quiz/types';
import { ICollaborationState } from 'lib/access-control';
import { AppState } from 'lib/app/types';
import { IArticlesState } from 'lib/articles/types';
import { CustomAuthErrorCodes, FirebaseErrorCodes, IAuthState } from 'lib/auth/types';
import { DatePickerType } from 'lib/datepicker/types';
import { IEnquiriesState } from 'lib/enquiries/types';
import { FeatureCardsService } from 'lib/feature-cards/feature-cards-service';
import { IFilesState } from 'lib/files/reducer';
import { IGuestlistState } from 'lib/guestlist/types';
import { IModulesState } from 'lib/modules/types';
import { IScrapbookState } from 'lib/scrapbook/types';
import { SettingsState } from 'lib/settings/types';
import { ISupplierState } from 'lib/supplier/types';
import { CordovaTrackerService } from 'lib/track-utils/services/cordova-tracker-service';
import { IUsersState } from 'lib/users/types';
import { MuteActionsService } from 'lib/utils/mute-actions-service';
import { WarningModalService } from 'lib/utils/warning-modal-service';
import validate from 'lib/validate';
import { IVenuerexState } from 'lib/venuerex/types';
import { NextPageContextWithBridebookExtras } from './auth/session';
import { IBBCommonState } from './bbcommon/types';
import { IBudgetState } from './budget/types';
import { IChecklistState } from './checklist/types';
import { IFirebaseDeps } from './configure-deps';
import { EpicsState } from './epics/types';
import { MobileAppState } from './mobile-app/types';
import { RWState } from './real-weddings/types';
import { ISearchState } from './search/types';
import { IShortlistState } from './shortlist/types';
import { TaskState } from './task/types';
import { UiState } from './ui/types';
import { IVenueConfirmState } from './venue-confirmation/types';
import { IWeddingsState } from './weddings/types';

export type { Action, ActionWithPayload, RecursivePartial } from '@bridebook/toolbox/src/types';

// General re-export
export * from 'lib/app/types';
export * from 'lib/mobile-app/types';
export * from 'lib/articles/types';
export * from 'lib/auth/types';
export * from 'lib/bbcommon/types';
export * from 'lib/budget/types';
export * from 'lib/checklist/types';
export * from 'lib/datepicker/types';
export * from 'lib/enquiries/types';
export * from 'lib/i18n/types';
export * from 'lib/guestlist/types';
export * from 'lib/weddings/types';
export * from 'lib/search/types';
export * from 'lib/settings/types';
export * from 'lib/shortlist/types';
export * from 'lib/epics/types';
export * from 'lib/supplier/types';
export * from 'lib/ui/types';
export * from 'lib/users/types';
export * from 'lib/venuerex/types';
export * from 'lib/venue-confirmation/types';
export * from 'lib/scrapbook/types';

// core types

export type ValueOf<T> = T[keyof T];

// These params will be added to state, as well as used in Cordova Universal Links
export const ALLOWED_CUSTOM_PARAMS = [
  'next',
  'searchPopup',
  'enquiryConfirmation',
  'enquirySupplier',
  'conversationId',
  'venueConfirmationPopup',
  'slug',
  'area',
  'id',
  'nonce',
  'resetemail',
  'page',
  'category',
] as const;

export type Query = Partial<
  Record<(typeof ALLOWED_CUSTOM_PARAMS)[number], string> & {
    slug: Slug | UrlFriendlySlug;
    market: string;
  }
>;

export interface NameValue {
  name: string;
  value: string;
}

type ExtendedError = Error & { code?: FirebaseErrorCodes | CustomAuthErrorCodes; prop: string };

export type BridebookError = ValidationError | ExtendedError;

export interface IApplicationState {
  app: AppState;
  auth: IAuthState;
  articles: IArticlesState;
  checklist: IChecklistState;
  shortlist: IShortlistState;
  search: ISearchState;
  budget: IBudgetState;
  guestlist: IGuestlistState;
  supplier: ISupplierState;
  ui: UiState;
  users: IUsersState;
  settings: SettingsState;
  newonboarding: NewOnboardingState;
  datepicker: DatePickerType;
  enquiries: IEnquiriesState;
  weddings: IWeddingsState;
  bbcommon: IBBCommonState;
  venueConfirmation: IVenueConfirmState;
  scrapbook: IScrapbookState;
  epics: EpicsState;
  mobileapp: MobileAppState;
  venuerex: IVenuerexState;
  files: IFilesState;
  modules: IModulesState;
  acl: ICollaborationState;
  realWeddings: RWState;
  quiz: QuizState;
  task: TaskState;
}

// Actions

export interface Entities {
  wedding: () => Weddings;
}

export interface IBasicDeps extends IFirebaseDeps {
  getState: () => IApplicationState;
  now: () => number;
  validate: typeof validate;
  cordovaTracker: CordovaTrackerService;
  muteActions: MuteActionsService;
  warningModal: WarningModalService;
  googlePlacesService: GooglePlacesService;
  autocompletePredictionsService: AutocompletePredictionsService;
  featureCardsService: FeatureCardsService;
}

export interface IDeps extends IBasicDeps, Entities, IConnectedDispatch {}

export type EpicEntity = Observable<Entities[keyof Entities]>;

export interface EpicEntities {
  wedding$: EpicEntity;
}

export interface IEpicDeps extends IBasicDeps, EpicEntities {
  state$: StateObservable<IApplicationState>;
}

export interface IQuery extends Record<string, any> {}

export type UrlQuery<T> = T extends ISearchPageQuery ? ISearchPageQuery : IQuery;

export interface IUrl<T = IQuery> {
  pathname?: string;
  query: UrlQuery<T>;
  slug?: UrlFriendlySlug;
  searchParams?: string[];
  area?: string;
  placeId?: string;
}

export interface CustomDispatch<T> extends Dispatch<Action> {
  (t: T): void;
}

export type ActionCreatorWithDeps<A extends Action> = (...args: any[]) => (deps: IDeps) => A;

export interface IConnectedDispatch<A extends Action = Action> {
  dispatch: CustomDispatch<A | ReturnType<ActionCreatorWithDeps<A>>>;
}

export type AppStore = Store<IApplicationState> & {
  dispatch: IConnectedDispatch['dispatch'];
};

export interface IOnClick<T extends HTMLElement = HTMLButtonElement> {
  onClick?: (e?: SyntheticEvent<T, Event>) => void;
}

export interface IIntlMessageDescriptor {
  id: string;
  defaultMessage: string;
  description?: string | Record<string, any>;
}

export type IReducersImmer<T> = (draft: T) => Record<string, (action?: any) => T | void>;

export type ConnectedTypes<T> = T extends InferableComponentEnhancerWithProps<infer Props, infer _>
  ? Omit<Props, 'dispatch'> & { dispatch: IDeps['dispatch'] }
  : never;

interface IMarker {
  id: string;
  lat: number;
  lng: number;
}

export type IUISupplierWithMarker = IUISupplier & IMarker;

// Allows to resolve return type of a promise
export type Await<T> = T extends PromiseLike<infer U> ? U : T;

// Allows to mark some properties as optional
export type WithOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

/* ############################################################################
 *  Next.js specific props
 * ######################################################################### */
/**
 * Passed as an argument to getInitialProps
 */
export type PageInitialProps = {
  ctx: NextPageContextWithBridebookExtras;
  store: Store<IApplicationState>;
  user?: string | null;
};

/**
 * Passed as an argument to page-wrapping HOCs
 */
export type CustomPage = ComponentType<any> & {
  getInitialProps: (props: PageInitialProps) => Record<string, any> | void;
};

/* ############################################################################
 *  DEPRECATED START
 * ######################################################################### */
/**
 * @deprecated
 * Use storeEnhancer approach with ConnectedProps from react-redux instead
 */
export type IConnectedProps<T extends (...args: any) => any> = ReturnType<T> & IConnectedDispatch;

/**
 * @deprecated
 * Use IConnectedDispatch instead
 */
export type ConnectedDispatch<T extends Action = any> = IConnectedDispatch<T>;

/**
 * @deprecated
 * Use IApplicationState instead
 */
export type State = IApplicationState;

/* ############################################################################
 *  DEPRECATED END
 * ######################################################################### */

export interface InputTarget<N = string> extends EventTarget {
  name: N;
  value: string;
}

export interface InputEvent extends Event {
  target: InputTarget;
  currentTarget: InputTarget;
}

interface WebSession {
  user_id?: string;
  provider_id?: string;
}

interface WebReq extends NextApiRequest {
  device?: { sessionId: string; isCordova: boolean };
  session?: WebSession;
  i18n?: {
    userId: string;
    baseUrl: string;
    supportedLanguages: string[];
  };
}

export interface WebPageContext extends NextPageContext {
  req: WebReq;
}

export type IEpic<Input extends AnyAction = AnyAction, Output extends AnyAction = AnyAction> = (
  action$: ActionsObservable<Input>,
  deps: IEpicDeps,
) => Observable<Output>;

export interface NewOnboardingState {
  onboardingModalVisible: boolean;
}

export interface QuizState {
  modal:
    | { open: true; quizId: TQuizId; quizSource: string; showIntroPage?: boolean }
    | { open: false; quizId?: TQuizId };
  quizCompleted: Record<string, boolean>;
  interceptSearchParams: string | null;
}

export type ReturnTypeAction<Type, Func extends (...args: any) => any> = Omit<
  ReturnType<Func>,
  'type'
> & {
  type: Type;
};
